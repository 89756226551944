.hot-search-container {
  width: 100vw;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.hot-search-container .container-items {
  background-color: #f7f8fa;
  padding: 8px 18px;
  border-radius: 8px;
  margin: 0 12px 18px 12px;
}
.hot-search-container .container-items .container-title .title-icon {
  width: 28px;
}
.hot-search-container .container-items .container-title .title-refresh {
  width: 12px;
  height: 12px;
}
.hot-search-container .container-items .hot-item {
  background-color: #fff;
  margin: 8px 0;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.hot-search-container .container-items .hot-item .hot-item-icon {
  width: 20px;
  height: 20px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  margin-right: 8px;
  font-family: monospace;
}
.hot-search-container .container-items .hot-item .hot-item-text {
  font-weight: 600;
  font-size: 14px;
  max-width: 200px;
  white-space: nowrap;
  /* 确保文本在一行内显示 */
  overflow: hidden;
  /* 超出容器的内容隐藏 */
  text-overflow: ellipsis;
  /* 使用省略号表示文本被截断 */
}
.hot-search-container .container-body {
  height: 468px;
  overflow-y: auto;
}
.hot-search-container .container-body div:nth-child(1) .hot-item-icon {
  background-color: #ED4F50;
  color: #fff;
}
.hot-search-container .container-body div:nth-child(2) .hot-item-icon {
  background-color: #f7a603;
  color: #fff;
}
.hot-search-container .container-items div:nth-child(3) .hot-item-icon {
  background-color: #f7c203;
  color: #fff;
}
.hot-search-container .container-footer {
  display: flex;
  justify-content: space-between;
  padding: 12px 0 0 0;
}
.hot-search-container .container-footer .refresh-date {
  font-size: 12px;
}
.hot-search-container .container-footer .spin {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  /* 更改此值以改变旋转速度 */
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  /* 使动画无限循环 */
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
